import accountsApi from '@/api/accounts';
import profileApi from '@/api/profile';
import apiService from '@/api';

const storageToken = localStorage.getItem('token') || '';
let userData = {};
if (storageToken.split('.')[1]) userData = JSON.parse(atob(storageToken.split('.')[1]));


export default {
  namespaced: true,
  state: {
    status: '',
    token: storageToken,
    user: userData,
    profile: {},
    userBankCards: [],
  },
  actions: {
    login({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        accountsApi
          .login(user)
          .then((resp) => {
            dispatch('loginSuccess', resp);
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            localStorage.removeItem('token');
            reject(err);
          });
      });
    },
    async loginSuccess({ commit, dispatch }, resp) {
        const token = resp.token || resp.access;
        localStorage.setItem('token', token);
        commit('auth_success', token);
    
        const profile = await dispatch('loadProfile');
        dispatch('loadCompany');
    
        return profile;
    },
    loadCompany({ commit }) {
      profileApi.getCompany().then((res) => {
        commit('setCompany', res);
      });
    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        accountsApi
          .register(user)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('token');
        delete apiService.defaults.headers.common.Authorization;
        resolve();
      });
    },
    async loadProfile({ commit }) {
        const res = await profileApi.get();
        commit('setProfile', res);
        return res;
    },
    updateProfile({ commit }, user) {
      return new Promise((resolve, reject) => {
        profileApi
          .update(user)
          .then((resp) => {
            //const { token } = resp;
            //localStorage.setItem('token', token);
            commit('setProfile', resp);
            resolve(resp);
          })
          .catch((err) => {
            //localStorage.removeItem('token');
            reject(err);
          });
      });
    },
    updateAvatar({ commit }, avatar) {
      return new Promise((resolve, reject) => {
        profileApi
          .updateAvatar(avatar)
          .then((resp) => {
            commit('setAvatar', resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setDefaultCompany({commit}, company_id) {
      return new Promise((resolve, reject) => {
        profileApi
          .setDefaultCompany(company_id)
          .then((resp) => {
            commit('setDefaultCompany', company_id);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setInvoiceCompany({commit}, company_id) {
      return new Promise((resolve, reject) => {
        profileApi
          .setInvoiceCompany(company_id)
          .then((resp) => {
            commit('setInvoiceCompany', company_id);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setDefaultBankCard({commit}, card_id) {
      return new Promise((resolve, reject) => {
        profileApi
          .setDefaultBankCard(card_id)
          .then((resp) => {
            commit('setDefaultBankCard', card_id);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchUserBankCards({ commit }) {
      return new Promise((resolve, reject) => {
        profileApi.getUserBankCards()
          .then(response => {
            commit('setUserBankCards', response);
            resolve(response);
          })
          .catch(error => {
            console.error('Error fetching user bank cards:', error);
            reject(error);
          });
      });
    },
    addUserBankCard({ commit }, userBankCardData) {
      return new Promise((resolve, reject) => {
        profileApi.createUserBankCard(userBankCardData)
          .then(data => {
            commit('addUserBankCard', data);
            resolve(data);
          })
          .catch(error => {
            console.error('Error adding user bank card:', error);
            reject(error);
          });
      });
    },
    deleteUserBankCard({ commit }, cardId) {
      return new Promise((resolve, reject) => {
        profileApi.deleteUserBankCard(cardId)
          .then(response => {
            commit('deleteUserBankCards', cardId);
            resolve(response);
          })
          .catch(error => {
            console.error('Error removing bank card:', error);
            reject(error);
          });
      });
    },
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, token) {
      if (typeof token !== 'string' || token === '') {
          console.error('Invalid token:', token);
          return;
      }
      state.status = 'success';
      state.user = JSON.parse(atob(token.split('.')[1]));
      state.token = token;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
      state.user = {};
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setAvatar(state, avatar) {
      state.profile.image = avatar.image;
    },
    setCompany(state, company) {
      state.company = company;
    },
    setDefaultCompany(state, company_id) {
      state.profile.default_ocr_company = company_id
    },
    setInvoiceCompany(state, company_id) {
      state.profile.invoice_company = company_id
    },
    setUserBankCards(state, userBankCards) {
      state.userBankCards = userBankCards;
    },
    addUserBankCard(state, userBankCards) {
      state.userBankCards.push(userBankCards);
    },
    deleteUserBankCards(state, cardId) {
      state.userBankCards = state.userBankCards.filter(card => card.id !== cardId);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    profile: (state) => state.profile,
    company: (state) => state.company,
    default_ocr_company: (state) => state.profile.default_ocr_company,
    userBankCards: (state) => state.userBankCards,
  },
};