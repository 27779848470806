import { createApp } from 'vue';
import App from './App.vue';
import router from './routes/index';
import store from './store/index';
import VueCookies from 'vue-cookies';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 
//import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd";
import "vuejs-clipper/dist/vuejs-clipper.css";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueUploadComponent from 'vue-upload-component'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Multiselect from '@vueform/multiselect';
import VueZoomer from 'vue-zoomer';
import { Cropper } from 'vue-advanced-cropper';
import vue3GoogleLogin from 'vue3-google-login';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';


const app = createApp(App);
app.use(router);
app.use(store);


app.use(VueCookies);
/* app.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperPreview: true,
    clipperFixed: true,
    clipperRange: true,
    clipperUpload: true
  }
}); */
app.use(VueAxios, axios);
app.use(VueZoomer)
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
})
app.use(Viewer);
app.component('Cropper', Cropper);
app.component('file-upload', VueUploadComponent)
app.component('VueDatePicker', VueDatePicker);
app.component('Multiselect', Multiselect);

app.mount('#app');
