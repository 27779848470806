import profileApi from '@/api/profile';

const storageToken = localStorage.getItem('token') || '';
let userData = {};
if (storageToken.split('.')[1]) userData = JSON.parse(atob(storageToken.split('.')[1]));

export default {
  namespaced: true,
  state: {
    status: '',
    token: storageToken,
    user: userData,
    company: []
  },
  actions: {
    loadCompany({ commit }) {
      profileApi.getCompany()
        .then((res) => {
          commit('setCompany', res);
        })
        .catch((error) => {
          console.error('Failed to load company:', error);
        });
    },
    createCompany({commit}, company) {
      return new Promise((resolve, reject) => {
        profileApi
          .createCompany(company)
          .then((resp) => {
            commit('createCompany', resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteCompany({commit}, company) {
      return new Promise((resolve, reject) => {
        profileApi
          .deleteCompany(company)
          .then((resp) => {
            commit('deleteCompany', company);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateCompany({commit}, { formData, companyId }) {
      return new Promise((resolve, reject) => {
        profileApi
          .updateCompany(companyId, formData)
          .then((resp) => {
            commit('updateCompany', resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadReport({ commit }) {
      profileApi.getCompany().then((res) => {
        commit('setCompany', res);
      });
    },

  },
  mutations: {
    setCompany(state, company) {
      state.company = company;
    },
    deleteCompany(state, company) {
      state.company.forEach((element, index) => {
        if (element.id == company.id) {
          state.company.splice(index, 1);
            return;
        }
      });
    },
    createCompany(state, company) {
      state.company.push(company)
    },
    updateCompany(state, company) {
      state.company.forEach((element, index) => {
        if (element.id == company.id) {
          state.company[index] = company;
            return;
        }
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    company: (state) => state.company,
  },
};