import apiService from './index';


export default {
  login(user) {
    return apiService.post('/auth/token/', user);
  },
  register(user) {
    return apiService.post('/user/create', user)
  },
  fetchDecryptedAPIKey() {
    return apiService.get('/auth/api-key/');
  },
  fetchDecryptedRevokedKey() {
    return apiService.get('/auth/revoked-key/');
  },
  generateAPIKey() {
    return apiService.post('/auth/regenerate_api_key/');
  },
  /* usingGoogle(token) {
    return apiService.post('/auth/google/', {
      code: token,
    });
  },
  usingLinkedIn(token) {
    return apiService.post('/auth/linkedin/', {
      access_token: token,
    });
  },
  usingTwitter(token) {
    return apiService.post('/auth/twitter/', {
      access_token: token,
    });
  }, */
  activateEmail(uid, key, token) {
    return apiService.get(`/auth/dj-rest-auth/registration/account-confirm-email/${uid}${key}${token}/`);
  },
};
