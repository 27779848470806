import apiService from './index';
import axios from 'axios';

const ME_ENDPOINT = 'user/me';
const COMPANY_ENDPOINT = 'ocr/company/';
const PAYMENTS_ENDPOINT = 'payments/';


export default {
  update(user) {
    return apiService.patch(ME_ENDPOINT, user);
  },
  get() {
    return apiService.get(ME_ENDPOINT);
  },
  setDefaultBankCard(card_id) {
    return apiService.put(`${PAYMENTS_ENDPOINT}assign_default_payment_method/`, {'payment_method_id':card_id });
  },
  setDefaultCompany(company_id) {
    return apiService.patch(ME_ENDPOINT, {'default_ocr_company':company_id});
  },
  setInvoiceCompany(company_id) {
    return apiService.patch(`${PAYMENTS_ENDPOINT}assign_invoice_company_to_user/`, {'invoice_company':company_id});
  },
  getCompany() {
    return apiService.get(`${COMPANY_ENDPOINT}ordered/`);
  },
  createCompany(company) {
    return apiService.post(COMPANY_ENDPOINT, company, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  updateCompany(companyId, formData) {
    return apiService.patch(`${COMPANY_ENDPOINT}${companyId}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteCompany(company) {
    return apiService.delete(`${COMPANY_ENDPOINT}${company.id}/`);
  },
  changePassword(currentPassword, newPassword) {
    return apiService.put('/user/change-password', {
      old_password: currentPassword,
      new_password: newPassword,
    });
  },
  checkPassword(password) {
    return apiService.post('/user/check-password', {
      current_password: password
    });
  },
  updateWebhookUrl(url) {
    return apiService.post('/user/create-webhook-url', url);
  },
  help(user) {
    return apiService.post('/user/help', {
      name: user.name,
      email: user.email,
      company: user.company,
      message: user.message
    });
  },
  reset(email) {
    return apiService.post('/auth/password-reset/', {email});
  },
  validatePasswordToken(token) {
    return apiService.post('/auth/password-reset/validate_token/', { token });
  },
  confirmResetPassword(token, password) {
    return apiService.post('/auth/password-reset/confirm/', { token, password });
  },
  getBankAccount() {
    return apiService.get('/user/get-mensaje-code');
  },
  getUserBankCards(){
    return apiService.get('/user/bank-cards');
  },
  createUserBankCard(userBankCardData) {
    const formData = new FormData();
    formData.append('bank_name', userBankCardData.bank_name);
    formData.append('last_digits', userBankCardData.last_digits);
    formData.append('payment_method', userBankCardData.payment_method);
    return apiService.post('/user/bank-cards/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 90 * 1000,
    });
  },
  deleteUserBankCard(cardId) {
    return apiService.delete(`/user/bank-cards/${cardId}`);
  }
};