import { createStore } from 'vuex';
import accounts from './accounts';
import company from './company';
import VuexPersistence from 'vuex-persist';


const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = createStore({
    state: {},
    modules: {
      accounts,
      company,
    },
    plugins: [vuexLocal.plugin]
});

export default store;