import axios from 'axios';
import VueCookies from 'vue-cookies';

const storageToken = localStorage.getItem('token');
const csrfToken = VueCookies.get('csrftoken');


const headers = {
  contentType: 'application/json',
};

if (storageToken) {
  headers.Authorization = `Bearer ${storageToken}`;
}
if (csrfToken) {
  headers['X-CSRFToken'] = csrfToken;
}

const BASE_URL = process.env.VUE_APP_BACKEND_URL || '';

const apiService = axios.create({
  timeout: 30000,
  headers,
  baseURL: BASE_URL,
});

apiService.interceptors.response.use(
  (response) => {
    if (!response || typeof response === 'undefined') {
      return { success: false };
    }
    if ('data' in response) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (!error.response) {
      return null;
    }
    if (error.response.status === 401) {
      const store = require('@/store/index'); // eslint-disable-line
      const router = require('@/routes'); // eslint-disable-line
        store.default.dispatch('accounts/logout').then(() => {
        router.default.push('/');
      });
      return error;
    }
    throw error;
  },
);

apiService.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : ''; // eslint-disable-line
  return config;
});


export default apiService

export { BASE_URL, headers };